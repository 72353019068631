<template>
  <div style="background-color: #EBEED7;">
   <section>
      <article
        style="padding-top: 160px;">
      
        <div class="container" style="margin: 50px auto; text-align: left">
          <div class="article_intro">
            <p class="locus_word_style">
                    <small>KINAWATAKA WOMEN INITIATIVES</small>
                  </p>
            <h2 style="font-weight: 600; font-size: 2em">
              Children
            </h2>
            <p style="">
                We identify needy children from different homes and support them to attain education. 
                We also train them to recycle used straws into different products
            </p>
            <p style="">
                
            </p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="mb-5">
        <div class="container">
            
        </div>
      </article>
    </section>
    
  </div>
</template>

<script>

export default {

  data() {
    return {
        
    };
  },

  methods: {
      
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .locus_word_style {
        font-size: 0.5em; font-weight: 300;
        word-spacing: 8px;
        letter-spacing: 5px;
    }
    .article_intro {
        width: 80%;
    }

    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) { }

    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { }

    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {
        .article_intro {
            width: 100%;
        }
    }

    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
        .article_intro {
            width: 100%;
        }
    }
</style>
